<div [ngClass]="{'desactivaPantalla': numero == 0 }">
    <mat-spinner *ngIf='numero == 0' class="spinner"></mat-spinner>
    <div class='row'>
        <span>&nbsp;&nbsp;{{data.message}}</span>
    </div>
    <br>
    <div class="float-right">
        <button class="separador" mat-raised-button color="accent"
        (click)='activar()'>Aceptar</button>
        <button mat-raised-button color="primary"
        (click)='cancelar()'>Cancelar</button>
    </div>
</div>