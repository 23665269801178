import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Material
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

//material core
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { SnackNotifyComponent } from './snack-notify/snack-notify.component';
import { ControlDialogComponent } from './control-dialog/control-dialog.component';
import { MatDialogModule } from '@angular/material/dialog'

import {
  DevExtremeModule,
  DxDataGridModule,
  DxFileUploaderModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxButtonModule,
  DxDropDownBoxModule,
  DxAutocompleteModule,
  DxTemplateModule
} from 'devextreme-angular';

@NgModule({
  declarations: [SnackNotifyComponent, ControlDialogComponent],
  imports: [
    MatDialogModule, MatButtonModule,
    // DevExtreme
    DevExtremeModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxAutocompleteModule,
    DxTemplateModule,
    DxTemplateModule
  ],
  exports: [
    CommonModule,
    MatTabsModule,
    MatNativeDateModule,
    MatRippleModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatGridListModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    SnackNotifyComponent,
    MatDialogModule,
    // DevExtreme
    DevExtremeModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxAutocompleteModule,
    DxTemplateModule,
    DxTemplateModule
  ],
  entryComponents: [SnackNotifyComponent, ControlDialogComponent]
})
export class SharedModule { }
