import { Component, OnInit, Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material';
import { MatSnackBar } from '@angular/material';

/**
 * Obtenemos el Mensaje a mostrar
 */
export interface SendData {
    message: string;
}

@Component({
    selector: 'app-alert-confirm',
    templateUrl: './alert-confirm.component.html',
    styleUrls: ['./alert-confirm.component.scss']
})
export class AlertConfirmComponent implements OnInit {
    numero: number;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<AlertConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SendData
    ) {
    }

    ngOnInit() {
    }

    cancelar(): void {
        this.dialogRef.close({ numero: 0, result: 'Operacion Cancelada!' });

    }

    activar(): void {
        this.dialogRef.close({ numero: 1, result: 'Operacion Aceptada!' });
    }

}
