import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { SnackNotifyComponent } from '../shared/snack-notify/snack-notify.component';
import { LoginResponse } from './login-response';
import { StorageService } from './storage.service';

declare const initCanvasLogin: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({
    userLogin: new FormControl('', [Validators.required]),
    passwordLogin: new FormControl('', [Validators.required])
  });

  hide: Boolean = true;
  error: Boolean = false;
  loading: Boolean = false;
  public Mensaje: string;
  public wait: boolean;
  destroySubscription: Subject<boolean> = new Subject<boolean>();


  constructor(
    private route: Router,
    public snackBar: MatSnackBar,
    private service: DataService,
    private storage: StorageService,
    private httpServiceClient: HttpClient
  ) {
    this.Mensaje = undefined;
    this.wait = false;
  }
  ngOnDestroy(): void {
    this.destroySubscription.next(true);
    this.destroySubscription.unsubscribe();
  }

  ngOnInit() {
    this.storage.removeCurrentSession()
    //initCanvasLogin()
  }

  public logIn(): void {
    this.wait = true;
    if (
      this.loginForm.controls['userLogin'].value != '' &&
      this.loginForm.controls['passwordLogin'].value != ''
    ) {
      // Se pone el login AD
      // const body = {
      //   "Nombre": this.loginForm.value.userLogin,
      //   "Password": this.loginForm.value.passwordLogin,
      // }

      // this.loginAd(body)

      const body = {
        u: this.loginForm.value.userLogin,
        p: this.loginForm.value.passwordLogin,
        a: 3
      }
      const data = btoa(JSON.stringify(body))

      // const params: any = {
      //   username: "hSevcWPUE8a5f82Q93QoXAho/AFX15MSdDscz+t2ItIkxOiSozgnrVc5EK3oIrxSPdBaWFiYl2Ff9tJ/AA+JpZ8Sh7VYtVFJKJya2vWmtN8czskh+//yIcl6EHznYyVE3h4EOq1VGGFxWKFMtJ6Q3w/blvCimHtVAFpe1Y/rICKqZwPoHn/23GBzELzqse/s4ggYydmk+A8p0vlE7uxxfK/TLdCA+RWnmOUJfSzUUwyB777emroaZGAhI6iEVkEzy3vwAipZamOyB4WvUQGSHh44gtFZp6FDFfKe1BmkGvGqUjSYLG0BCcJ+5LrMBT3nL4k9mhks9qVk92OlCI8KqMwpVG236F+43tPGl6/siXq6DdI60i8x3xVnVd6vh/gB6fHLjgwGq2WAhtqNtqQ9tTZWz/R81nwm5HPQxiEtm5l22USKClwqF84+7q9ANc+wgijaQLDH3gGAvW44u/nPIsTtKanN1t24oJNbE2NgTNpsHl9oVqHbX7aBCj05b3gJmh3YwIUByjs9lRj5uqURDF5ME0asV81e+tr51yEMd7qwWFC3SOIIAzZNIjndbxFYshO7mmKEV5VmNrj+7KkTFJwI44Nx+FUd2RhNihhv0Z5TEyNJDG8hKXShHJ8hRFDSHbyYzNXJX82rO2P3qa2orvIJBMiMMX2K2VdPP4xThvA=",
      //   password: "nDS9knQNbx6NhspLOdOevi0Td+XdSwMl8pTywlD+NE9AW1tEpuFd51VhTkVejAScg5ArzSbfzYQhAEcczIfRCB2GwsaabBQzm11qnAawmVgVdXFkk4DgsSxJIeW3eDUUUh9ukwkx8yoMibVggUZwtitgRyOS5ISdTe/728p7DUeSmCZfNPdwW4635I832yNIJXsgTdX1TvGsez1CwcVOlETjzml5D1WCNrSSnR+uicoXUNnjmETxCZhVbu3FzBxa4mJV4St8SdXdvDM/Ze0DFZsGQC8LZPiTF711EfDvQTTSMqqBak7aYYp7Pj/dEUVqmeBH5g5FeZVZJrPo+kN+itIzdeeCrElkrpUKZu/OFq173Bjt+Q/5rCVWmG4JYrxJVjUXKViCZYjCt5KPGuNH3hnAGD9pXvOTcZEaQ+kw5jls+9mJGuuE5DRrq78Pk/UYgTsq972Z/y7uhBNBb7Vc5fdqXQ3CYygGo7iHsuLdYBMu7mIS+8n449+BZ2KOvD7vZxR6hPfDI/lpjfKM6bMhXzL+SQrwScEqnPz04MUMRCWFJrKIoExUZN+xEtVxqAunrnME+jIU60eNnZn2aKn2ypgLFCNqiBTyqBiK/4pqutCcPGFlBPahwbhayKY+Z8elzJ1AcadaB4VPts4AhR4VQA79jmzLRdOjMGcmIBFDXDQ=",
      //   id_app: "1"
      // }
      // this.service
      //   .postRequest("comparnet/", params)
      //   //.pipe(takeUntil(this.destroySubscription))
      //   .subscribe((responseLogin: LoginResponse) => {
      //     console.log('responseLogin', responseLogin);
      //     if (responseLogin && !(responseLogin as any).Error) {
      //       this.loginProcess(responseLogin)
      //     } else {
      //       this.wait = false;
      //       this.showSnackMessage("Ocurrió un detalle en la comunicación con el servidor, revisar con su administrador.")
      //     }
      //   }, (err) => {
      //     alert('ERROR');
      //   });
      this.service.getRequest("utils/encript", { data: data }).subscribe((responseLogin) => {
        const respuesta = atob(responseLogin.resp);
        this.newLogin(JSON.parse(respuesta));
      }, (err) => {
        this.wait = false;
        alert('ERROR');
      });
    } else {
      this.showSnackMessage("Ingrese los campos obligatorios")
      this.wait = false;
    }
  }

  private newLogin(data) {
    this.service.postRequest("ComparnetLoginV2/", data)
      //.pipe(takeUntil(this.destroySubscription))
      .subscribe((resp) => {
        this.loginProcess(resp)
      }, (err) => {
        this.wait = false;
        alert('ERROR');
      });
  }

  private loginAd(data) {
    this.service.postRequest("ComparnetLoginAD/", data)
      //.pipe(takeUntil(this.destroySubscription))
      .subscribe((resp) => {
        if (resp.Estatus && resp.Nombre !== null && resp.Nombre !== '') {
          this.ObtieneDatosUsuario();
        } else {
          this.wait = false;
          this.showSnackMessage("El usuario o la contraseña son incorrectos")
        }
      }, (err) => {
        this.wait = false;
        alert('ERROR');
      });
  }

  public postRequest(methodName: String, bodyRequest: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpServiceClient
      .post<Response>('https://comparnet-backend.dev.cloud.gentera.com.mx/' + methodName, bodyRequest ? bodyRequest : {}, httpOptions);
    //.pipe(catchError(this.handleError));
  }

  ObtieneDatosUsuario() {
    let params: any = {
      vcNumeroNomina: this.loginForm.value.userLogin
    }
    this.service.getRequest(`Colaborador`, params)
      //.pipe(takeUntil(this.destroySubscription))
      .subscribe((resp) => {
        const datos = resp.datos
        if (datos.length > 0) {
          let nombreUsuario = ''
          if (datos[0].nombreUsuario) {
            nombreUsuario = datos[0].nombreUsuario
          } else {
            nombreUsuario = datos[0].nombre_usuario
          }
          const data = {
            code: "200",
            data: {
              NominaJefe: datos[0].numeroNomina,
              Perfil: datos[0].nombre_perfil,
              SedeId: datos[0].areaId,
              Sistema: datos[0].nombre_sistema,
              // Token: datos[0].,
              nombre: datos[0].nombre + ' ' + datos[0].apellidoPaterno + ' ' + datos[0].apellidoMaterno,
              nombreUsuario: nombreUsuario,
              nomina: datos[0].numeroNomina,
              tokenFirebase: datos[0].TokenFirebase,
              usuarioId: datos[0].usuarioId
            }
          }
          this.loginProcess(data)
        } else {
          this.showSnackMessage("Hubo un error al obtener los datos del usuario")
        }
      }, (err) => {
        this.wait = false;
        alert('ERROR');
      });
    // this.loginProcess(resp)
  }

  loginProcess(login) {
    if (login.code.localeCompare("200") == 0) {
      this.storage.setCurrentSession({ token: login.data.Token, user: login.data })
      this.route.navigate(['/administrator']);
    } else {
      this.wait = false;
      this.showSnackMessage(`${login.code} - ${login.data.descripcion}`)
    }

  }

  showSnackMessage(messageSnack: String) {
    this.snackBar.openFromComponent(SnackNotifyComponent,
      {
        data: messageSnack,
        duration: 4000,
      }
    )
  }

}
