<div class="login-page">
    <mat-card class="mat-elevation-z12">
        <mat-card-title style="text-align:center;">
            <img class="imgLogo" src="./assets/images/logo.png" />
        </mat-card-title>
        <mat-tab-group color="accent" mat-stretch-tabs class="example-stretched-tabs">
            <mat-tab class="tabLogin">
                <ng-template mat-tab-label>
                    <span class="tabLabelClass">COMPARNET</span>
                </ng-template>
                <mat-card-content>
                    <form *ngIf="!wait" action="" [formGroup]="loginForm">

                        <mat-form-field class="inputLogin" color="accent">
                            <input matInput placeholder="Usuario" required formControlName="userLogin">
                            <mat-error *ngIf="loginForm.controls['userLogin'].hasError('required')">El usuario es
                                obligatorio</mat-error>
                            <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>

                        <mat-form-field class="inputLogin" color="accent">
                            <input matInput placeholder="Contraseña" required type="password" required minlength="6"
                                formControlName="passwordLogin">
                            <mat-error *ngIf="loginForm.controls['passwordLogin'].hasError('required')">
                                La contraseña es obligatoria
                            </mat-error>
                            <mat-icon matSuffix>lock</mat-icon>
                        </mat-form-field>

                        <mat-error *ngIf="Mensaje">
                            {{Mensaje}}
                        </mat-error>
                        <button mat-raised-button color="accent" class="login-button" (click)="logIn()">
                            Ingresar
                        </button>
                    </form>
                    <div *ngIf="wait" class="spinnerContain">
                        <mat-spinner strokeWidth="3" [diameter]="50" color="accent"></mat-spinner>
                    </div>
                </mat-card-content>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>