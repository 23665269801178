import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
//Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModules } from './material-module';
//client http
import { HttpClientModule } from '@angular/common/http';
//shared
import { SharedModule } from './shared/shared.module';
//hammer
import { GestureConfig } from '@angular/material';

// Utilerias
import { AlertConfirmComponent } from "./utilerias/alert-confirm/alert-confirm.component";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertConfirmComponent
  ],
  entryComponents: [
    AlertConfirmComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatIconModule,
    BrowserAnimationsModule,
    SharedModule,
    // DevExtreme
    // Angular Material Components
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MaterialModules,
    HttpClientModule,


  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig
  }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
