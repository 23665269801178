import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialogdata } from 'src/app/interfaces/dialogdata';

@Component({
  selector: 'app-control-dialog',
  templateUrl: './control-dialog.component.html',
  styleUrls: ['./control-dialog.component.scss']
})
export class ControlDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ControlDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public dataDialogObj: Dialogdata
    ) { }

  ngOnInit() {
  }

  onCancelClick(): void{
    this.dialogRef.close()
  }

}
