import { Injectable } from '@angular/core';
import { Router } from '@angular/router' 
import { Session } from './session'
import { User } from '../login/user'

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private localStorageService;
  private currentSessionObject;

  constructor(private router: Router) {

    this.localStorageService = sessionStorage;
    this.currentSessionObject = this.loadSessionData();
   }

   setCurrentSession(session: Session): void{
      this.currentSessionObject = session;
      this.localStorageService.setItem('currentUserSession', JSON.stringify(session));
   }

   loadSessionData(): Session{
     var sessionString = this.localStorageService.getItem('currentUserSession');
     return (sessionString) ? <Session> JSON.parse(sessionString) : null;
   }

   getCurrentSession(): Session{
      return this.currentSessionObject;
   }

   removeCurrentSession(): void {
     this.localStorageService.removeItem('currentUserSession');
     this.currentSessionObject = null;
   }

   getCurrentUser(): User{
     var localSession: Session = this.getCurrentSession();
     return (localSession && localSession.user) ? localSession.user : null;
   }

   isAuthenticated(): boolean{
     return (this.getCurrentToken()!=null) ? true: false;
   }

   getCurrentToken(): string{
     var sessionObtained = this.getCurrentSession();
     return (sessionObtained && sessionObtained.token) ? sessionObtained.token : null;
   }

   logout(): void {
     this.removeCurrentSession();
     this.router.navigate(['/login']);
   }
}
