import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'


@Injectable({
  providedIn: 'root'
})
export class DataService {
  httpOptions: any
  constructor(private httpServiceClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }
  }
  public get(endPoint: String, myParams: any = {}): Observable<any> {
    let params: HttpParams = new HttpParams({ fromObject: myParams });
    console.log('params ', params.keys());
    const urlGET = environment.baseUrlServices + endPoint;
    return this
      .httpServiceClient
      .get<Response>(urlGET);
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  public getRequest(methodName: String, paramsRequest: any): Observable<any> {
    let params = '';
    for (let p in paramsRequest) {
      params += `${p}=${paramsRequest[p]}&`;
    }
    const urlGET = paramsRequest ? `${environment.baseUrlServices}${methodName}/?${params.slice(0, params.length - 1)}` : environment.baseUrlServices + methodName;
    return this
      .httpServiceClient
      .get<Response>(urlGET)
      .pipe(catchError(this.handleError));
  }

  public postRequest(methodName: String, bodyRequest: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this
      .httpServiceClient
      .post<Response>(environment.baseUrlServices + methodName, bodyRequest ? bodyRequest : {}, httpOptions);
      //.pipe(catchError(this.handleError));
  }


  public putRequest(methodName: String, bodyRequest: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this
      .httpServiceClient
      .put<Response>(environment.baseUrlServices + methodName, bodyRequest ? bodyRequest : {}, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public deleteRequest(methodName: String, bodyRequest?: any): Observable<any> {

    return this
      .httpServiceClient
      .request('delete', environment.baseUrlServices + methodName, { body: bodyRequest ? bodyRequest : {} })
      .pipe(catchError(this.handleError));
  }

}