import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-snack-notify',
  templateUrl: './snack-notify.component.html',
  styleUrls: ['./snack-notify.component.scss']
})
export class SnackNotifyComponent implements OnInit {

  constructor(public snackBarNotify: MatSnackBarRef<SnackNotifyComponent>,
  @Inject(MAT_SNACK_BAR_DATA) public snackData: any){}
  
  ngOnInit() {
  }

}
